import React, { useState } from "react";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="bg-primary text-light p-4">
      <div className="container mx-auto flex justify-between items-center">
        <a href="/" className="flex items-center">
          <img src="/LOGO_BLANCO.png" alt="Biandré Logo" className="h-10 mr-2" />
          <span className="text-2xl font-bold text-accent">Biandré</span>
        </a>
        <ul className="hidden md:flex space-x-6">
          <li><a href="#" className="hover:text-accent transition">Inicio</a></li>
          <li><a href="#" className="hover:text-accent transition">Servicios</a></li>
          <li><a href="#" className="hover:text-accent transition">Nosotros</a></li>
          <li><a href="#" className="hover:text-accent transition">Contacto</a></li>
        </ul>
        <button className="md:hidden focus:outline-none" onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? "✖" : "☰"}
        </button>
      </div>
      {isOpen && (
        <ul className="md:hidden bg-secondary p-4 space-y-2">
          <li><a href="#" className="block hover:text-accent transition">Inicio</a></li>
          <li><a href="#" className="block hover:text-accent transition">Servicios</a></li>
          <li><a href="#" className="block hover:text-accent transition">Nosotros</a></li>
          <li><a href="#" className="block hover:text-accent transition">Contacto</a></li>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
