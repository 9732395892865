import React from "react";

const Hero = () => {
  return (
    <section className="bg-primary text-light min-h-screen flex flex-col items-center justify-center text-center px-6">
      {/* Logo arriba del texto */}
      <img src="/LOGO_SIN_FONDO.png" alt="Biandré Logo" className="h-64 mb-1" />
      <h1 className="text-5xl md:text-6xl font-bold text-accent mb-2">
        Bienvenido a Biandré 🚀
      </h1>
      <p className="text-lg md:text-xl text-light max-w-2xl">
        Soluciones innovadoras en cartografía, drones y desarrollo de software.
      </p>
      <div className="mt-6 flex space-x-4">
        <a href="#" className="bg-accent hover:bg-green-500 text-white font-semibold py-3 px-6 rounded-lg shadow-lg transition">
          Conoce más
        </a>
        <a href="#" className="border border-light hover:border-accent text-light hover:text-white font-semibold py-3 px-6 rounded-lg transition">
          Contáctanos
        </a>
      </div>
    </section>
  );
};

export default Hero;
