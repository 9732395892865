import React from "react";

const Footer = () => {
  return (
    <footer className="bg-primary text-light py-10">
      <div className="container mx-auto text-center">
        <p className="text-sm text-light">
          © {new Date().getFullYear()} Biandré. Todos los derechos reservados.
        </p>
        <div className="flex flex-wrap justify-center gap-4 mt-6">
          <a href="#" className="hover:text-accent transition">Política de privacidad</a>
          <a href="#" className="hover:text-accent transition">Términos y condiciones</a>
          <a href="#" className="hover:text-accent transition">Contacto</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
